.card-dash{
    min-height: 105px;
    border-radius: 10px;
    // width: 221px;
}
.card-clr-dash{
    width: 44px;
    border-radius:10px;
}

.ant-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: white;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}
.table-dash .ant-table-thead > tr > th {
    position: relative;
    color: grey;
    font-weight: 500;
    text-align: left;
    background: white;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;}

    .ant-input:placeholder-shown,
    .ant-input-number:placeholder-shown,
    .ant-picker-input > input:placeholder-shown,
    .rc-time-picker-input:placeholder-shown,
    .ant-input-number-input:placeholder-shown,
    span.ant-select-selection-item {
        text-transform: capitalize;
      }

    li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-open {
        color:#3A61A8;
    }
    
    li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-open >.ant-menu-submenu-title >i.ant-menu-submenu-arrow {
        color: #3A61A8;
    }
    li.ant-menu-item.ant-menu-item-selected svg.menuitem.ant-menu-item-icon {
        fill: #3A61A8;
    }
    li.ant-menu-item.ant-menu-item-selected > span.ant-menu-title-content {
        color: #3A61A8;
    }
    li.ant-menu-item.ant-menu-item-selected > span.ant-menu-title-content a   {
        color: #3A61A8;
    }
    li.ant-menu-item.ant-menu-item-selected > span.ant-menu-title-content a span {
        color: #3A61A8;
    }
    li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-selected >.ant-menu-submenu-title >i.ant-menu-submenu-arrow {
        color: #3A61A8;
    }
    li.ant-menu-submenu.ant-menu-submenu-inline.menuitem.ant-menu-submenu-selected {
        color: #3A61A8;
    }
    .grapmapclass{
        overflow:hidden;
        height:380px; 
        padding:15px;
    }
    .grapmapclass canvas{
        margin: 15px 0px;
    }

    .mainlayout{
        overflow:initial;
        background-color: #FAFBFD;
    }
    .ant-tabs-nav-list{
        margin-top: 25px;
        margin-left: 75px;
        border-radius: 20px;
    }   

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    // border-bottom-color: #cf1616;
    background-color: #e6f7ff;
    border-radius: 12px;
    // box-shadow: 0px 5px 10px 0px #A6B5E9;
}
// .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active:hover {
  
//     transform: translateY(5px);
//   box-shadow: inset 0px 5px 10px 0px #A6B5E9;
// }
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 39px;
    border-radius: 12px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 12px;
}
    
@media (max-width:992px) {
    .grapmapclass {
        overflow: hidden;
        height: 300px;
        padding: 15px;
    } 
}
@media (max-width:768px) {
    .grapmapclass {
        overflow: hidden;
        height: 240px;
        padding: 15px;
    }
}