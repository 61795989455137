.admin-login-form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > .ant-row {
    width: 250px;
  }
}
.login-fields {
  margin: 0px auto;
  width: 100%;
  height: auto;
  padding: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  max-width: 500px;
  @media (max-width: 500px) {
    max-width: 90%;
  }
}

.login-form-button {
  background-color: #949caa;
  border-color: #949caa;
  border-radius: 15px;
  float: right;
  //   font-family: avenirmedium;
  font-size: 15px;
  height: 50px;
  width: 100%;
  &:hover {
    border-color: #949caa;
    background-color: #949caa;
  }
}

.login-form-forgot {
  color: #868b99;
  font-size: 14px;
  float: right;
}

input#editForm_data {
  padding: 0px;
}

.password-row {
  margin-top: 12px;
}
.signUp-fields {
  margin: 0px auto;
  width: 100%;
  height: auto;
  padding: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 10px;
  max-width: 500px;
  @media (max-width: 500px) {
    max-width: 90%;
  }
}

.form-input-phone {
  border-radius: 5px;
  padding: 4px 11px;
  width: 100% !important;
  height: 50px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
  box-shadow: inset 4px 4px 6px rgba(0, 0, 0, 0.1),
    inset -4px -4px 6px rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 25px;
}
.ant-input-number {
  height: 40px;
  border-radius: 5px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  // border-color: #d9d9d9 !important;
}

.select_Height .ant-select-selection-item {
  width: 140px;
  font-size: 16px;
}

.select_Height.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px !important;
  border-radius: 5px !important;
}

.select_Height.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2.5px !important;
}

.margin_bottom {
  margin-bottom: 5px;
}
.myprofile .ant-form-item-label label {
  font-size: 16px !important;
}
.profilesetting .ant-form-item-label label {
  font-size: 16px !important;
}
.iconprifleview {
  font-size: 20px;
  max-width: 60px;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.iconprifleview a,
.iconprifleview span {
  font-size: 100%;
  color: #969eac;
}
.stauscontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  margin: 0px auto;
  max-width: 100%;
  align-content: center;
  line-height: normal;
}
.stauscontent p {
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 400;
  color: #7d7d7d;
  width: 100%;
}
.stauscontent span {
  margin: 5px 0px;
  font-size: 18px;
  font-weight: 600;
  color: #222;
  width: 100%;
}
.socialmedia {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
}
.socialmedia a {
  margin: 5px 0px;
  font-size: 20px;
  font-weight: 600;
  color: #949caa;
  width: 100%;
  max-width: max-content;
}
