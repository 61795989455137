@import "./Stylesheet/_typography.scss";
@font-face {
  font-family: "nasalization";
  src: url("./Stylesheet/fonts/nasalization-rg.ttf");
}
@font-face {
  font-family: "Lato-Black";
  src: url("./Stylesheet/fonts/Lato/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  src: url("./Stylesheet/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Light";
  src: url("./Stylesheet/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src: url("./Stylesheet/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Semibold";
  src: url("./Stylesheet/fonts/Lato/Lato-Light.ttf") format("truetype");
}

body {
  font-family: "Lato-Black", "Lato-Bold", "Lato-Light", "Lato-Regular",
    "Lato-Semibold";
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }
.background-transparent .ant-drawer-content {
  background: rgba(0, 0, 0, 0.05) !important;
}
.react-tel-input .selected-flag {
  height: 45px !important;
}
.react-tel-input .form-control {
  height: 42px !important;
}
.react-tel-input {
  padding-left: 0px !important;
}
.ant-select-selector {
  height: 40px !important;
}
.ant-tabs-tab-btn {
  font-size: 20px;
}
.otp-Outline {
  justify-content: center;
  margin-top: 2vw;
}
.input_btn_style {
  margin-right: 28px;
}
.otp_input {
  width: 2.2em !important;
  height: 2em;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  font-size: 25px;
  font-weight: 600;
  color: black;
  background: #f5f5f5;
  box-shadow: inset 4px 4px 6px rgba(0, 0, 0, 0.1),
    inset -4px -4px 6px rgba(255, 255, 255, 0.5) !important;
}
.ant-collapse-borderless {
  background-color: #ffffff !important;
}

.collapse-body .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 16px 0px;
}
