.scrollspy {
  background-color: #fafbfd;
  top: 0;
  right: 0;
}
a {
  color: black;
}
.isCurrent {
  font-weight: bold;
}

.isCurrent a {
  color: #3a61a8;
}
.section.ant-layout.ant-layout-has-sider.fullsitelayout {
  background-color: #fafbfd !important;
}
.ant-table-thead > tr > th {
  background: #f9f9fd !important;
}
.ag-root-wrapper.ag-layout-normal {
  height: 103%;
}
.checkbox-blue .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #28589a;
  border-color: #28589a;
}
.checkbox-green .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #31A870;
  border-color: #31A870;
}