.ant-card-meta-description {
  margin-top: -40px !important;
}
.ant-card-meta-title {
  margin-bottom: 0px !important;
  margin-top: -10px !important;
}

.logo_container {
  display: flex;
  flex-direction: column;
  background-color: #3a61a8 !important;

  .brand {
    font-family: "nasalization";
    text-transform: uppercase;
    font-size: 37px;
    line-height: 53px;
    letter-spacing: 1.32px;
    text-align: center;
    color: #ffffff;
  }
}

.logo-header {
  object-fit: fill;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #fff;
}
.panel_panel__oaZ1B {
  width: 100%;
  // height: 92vh;
  @media (max-width: 1440px) {
    height: 87vh;
  }
}

.side-menu {
  overflow: auto !important;
  height: 100% !important;
  background: #fff !important;
  z-index: 100;
  position: fixed;
}

.side-menu-proposal {
  overflow: auto !important;
  height: 100% !important;
  position: fixed !important;
  left: 0px !important;
  top: 9.65vw !important;
  bottom: 0px !important;
  background: #fafbfd !important;
  z-index: +9 !important;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  left: 0;
  padding: 8px calc(50% - 26px / 2);
  text-overflow: clip;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu
  > .ant-layout-sider-children
  > ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-inline-collapsed
  a
  > .max_sidebarlogo {
  display: none;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger.side-menu
  > .ant-layout-sider-children
  > ul.ant-menu.ant-menu-root.ant-menu-inline
  > a
  > .min_sidebarlogo {
  display: none;
}
.min_sidebarlogo img.logo-header {
  max-width: 40px;
}
.achoreTagClick::before {
  display: block;
  content: " ";
  height: 85px;
  visibility: hidden;
  pointer-events: none;
}

.max_sidebarlogo img.logo-header {
  max-width: 220px;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #949caa;
  line-height: 48px;
  text-align: center;
  background: #e6f7ff;
  cursor: pointer;
  transition: all 0.2s;
}
.header_styling {
  padding: 0px !important;
  height: 40px !important;
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.side-menu
  ~ section.ant-layout.sitecontentlayout
  > header
  > section
  > header.ant-layout-header.header_styling {
  max-width: 95.6%;
}
.header_border {
  border-bottom: 1px solid #e1e1e1;
}
.ant-popover-content {
  margin-top: -15px !important;
}
.ant-popover-arrow {
  margin-top: -15px !important;
}

.select_header {
  font-size: 18px !important;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  color: #131419 !important;
  font-weight: 600 !important;
}
.select_header .ant-select-selection-item {
  font-weight: 600 !important;
  color: #000 !important;
}
.option_style {
  font-weight: 500 !important;
}

.ant-select-arrow {
  font-size: 13px !important;
  font-weight: bolder !important;
  color: black !important;
}
.menuitem {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 14px;
}

.menuitem_lin {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  font-family: "DM Sans", Arial, sans-serif;
  font-style: normal;
  line-height: 16px;
}

.title_changes {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif !important;
  color: #000;
}

.profilecontentdd {
  float: right;
  padding: 10px;
  height: auto;
  margin-left: 1%;
}
.fixedprofile {
  display: flex;
  flex-flow: row nowrap;
  margin-left: auto;
  max-width: max-content;
  min-width: 500px;
  width: max-content;
  justify-content: right;
  height: 100%;
}
section.ant-layout.ant-layout-has-sider.fullsitelayout {
  background-color: white !important;
}
.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  padding-left: 0px !important;
}
.ant-tooltip > .ant-tooltip-content > .ant-tooltip-inner a span.menuitem {
  color: #495057 !important;
}
.ant-tooltip
  > .ant-tooltip-content
  > .ant-tooltip-arrow
  > span.ant-tooltip-arrow-content {
  --antd-arrow-background-color: white !important;
  display: none;
}
.ant-table.ant-table-fixed-header.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body {
  overflow: auto !important;
}
.ant-layout {
  overflow: hidden !important;
}
@media (max-width: 992px) {
  .header_styling {
    z-index: 1 !important;
    width: 100% !important;
    padding: 0px !important;
    height: auto !important;
    max-width: 100% !important;
    position: "sticky";
  }
  .header_styling1 {
    z-index: 1 !important;
    width: 100% !important;
    padding: 0px !important;
    height: auto !important;
  }
  .mainlayout {
    padding-top: 0px;
  }
  .dark-mode {
    color: "#ffffff";
    background-color: "#2A2A2C";
  }
  .form-input {
    height: 40px;
    border-radius: 5px;
    padding: 4px 11px;
  }
  .header_styling > .ant-row > .ant-col > .ant-row {
    margin: 10px !important;
  }
}
.aerosimple-title {
  width: 100%;
  padding: 0px 28px !important;
  background-color: #3a61a8 !important;
  position: "fixed";
  height: 55px;
  color: #ffff !important;
  text-transform: uppercase;
  h2 {
    font-family: "nasalization";
    text-transform: uppercase;
    font-size: 24px;
    padding-left: 0px;
    color: #fff;
    height: 40px;
    width: 250px;
  }
}
.head {
  background-color: #3a61ab;
  margin-right: 1px;
  text-align: start;
}
.button-style {
  background-color: #3a61ab;
}
.header-card {
  .ant-card-body {
    padding: 0px;
  }
}
.aero-title {
  color: #ffff !important;
  font-size: 34px;
  text-align: left;
}
.button_proposal {
  height: 50px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(66, 98, 163);
  color: rgb(66, 98, 163);
}

.button_proposal:hover {
  height: 50px;
  background-color: #4262a3 !important;
  border: 1px solid rgb(66, 98, 163);
  color: #ffff;
}

.leaflet-div-icon {
  background: none;
  border: none;
}
.ant-layout-footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 13px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  background: #fff;
  text-align: center;
}

.leaflet-pane {
  z-index: 0 !important;
}
.leaflet-touch .leaflet-bar a {
  display: none !important;
}

.aero-solu {
  margin-top: 1vw;
  margin-bottom: 2vw;
}
.panel_panel__oaZ1B .panel_content__52roW {
  overflow: auto;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1 1;
  font-size: 12px;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 3px;
}

.ant-table-tbody > tr:hover td {
  background-color: rgba(183, 214, 240, 0.4) !important;
}
.customergrid .ant-table.ant-table-fixed-header {
  @media only screen and(max-width:1920px) {
    height: 91%;
  }
  @media only screen and (max-width: 1280px) {
    height: 69vh;
  }
}
.reports_table {
  height: 84vh;
  @media only screen and (max-width: 1280px) {
    height: 78vh;
  }
}
.ant-table-pagination.ant-pagination {
  margin: 280px 21px;
}
.ant-table-sticky-scroll {
  bottom: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  opacity: -0.4;
}

.viewStyles {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
table.Customers_table__-ffau.undefined {
  border: 0px;
}
tr.Customers_position_row__jBlgT {
  border-top: 1px solid #e6eaee;
}

.panel_panel__SeWhP .panel_content__DBdCF {
  height: calc(100% - 54px);
  overflow: auto;
}
@media (max-width: 1536px) {
  .panel_panel__SeWhP {
    height: 90vh;
  }
}
.custom-drawer-contents .ant-drawer-close {
  display: none;
}
.columns-Titles {
  line-height: 1rem;
  font-weight: 700;
  color: rgb(107, 114, 128);
  text-transform: uppercase;
  font-family: Helvetica Neue, ui-sans-serif, system-ui, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Symbol, "Noto Color Emoji";
  font-size: 12px;
  letter-spacing: 0.6px;
  line-height: 16px;
}
.column_value {
  --tw-bg-opacity: 1;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-family: Helvetica Neue, ui-sans-serif, system-ui, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Symbol, "Noto Color Emoji";
}
.customer_title {
  color: var(--black, #131313);
  font-weight: bold;
  font-size: 17px;
  font-family: "DM Sans", Arial, sans-serif;
}
.customer_formNames {
  color: var(--no-active, #aea9a9);
  font-family: "DM Sans", Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.customer_formInput {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.edit_fontValues {
  color: var(--black, #161616);
  font-family: "DM Sans", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
}
.Opportunities.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.select_option.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.Opportunity_table {
  padding: 20px;
  height: 93vh;
  //  background-Color: #fff;
  @media (max-width: 1440px) {
    height: 90vh;
  }
  @media (max-width: 1536px) {
    height: 94vh;
  }
}
.customer_table {
  padding: 20px;
  height: 100vh;
  @media (max-width: 1440px) {
    height: 100vh;
  }
  @media (max-width: 1536px) {
    height: 100vh;
  }
}
.customer_allData {
  height: 69vh;
  background-color: #fff;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 63vh;
  }

  @media (max-width: 1536px) {
    height: 63vh;
  }
}
.webLeads_allData {
  height: 70vh;
  background-color: #fff;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 63vh;
  }

  @media (max-width: 1536px) {
    height: 63vh;
  }
}
.all_tables {
  background-color: #fff;
  height: 58vh;
  @media (max-width: 1440px) {
    height: 45vh;
  }

  @media (max-width: 1536px) {
    height: 45vh;
  }
}
.edit_btn:hover {
  background-color: red;
}
.table_responsive {
  display: block;
  margin-top: 110px;
  height: 65vh;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 53vh;
  }
  @media (max-width: 1536px) {
    height: 53vh;
  }
}
.opportunity_table {
  display: block;
  margin-top: 80px;
  height: 68vh;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 57vh;
  }
  @media (max-width: 1536px) {
    height: 57vh;
  }
}
.leads_table {
  display: block;
  margin-top: 60px;
  height: 68vh;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 55vh;
  }
  @media (max-width: 1536px) {
    height: 57vh;
  }
}
.wbeLeads_htmlTable {
  display: block;
  height: 75vh;
  overflow-y: auto;
  @media (max-width: 1440px) {
    height: 45vh;
  }
  @media (max-width: 1536px) {
    height: 68vh;
  }
}
form#add_note .ql-toolbar.ql-snow {
  background-color: #f9f9fd;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.notes_content {
  height: 42vh;
  @media (max-width: 1440px) {
    height: 52vh;
  }
  @media (max-width: 1536px) {
    height: 52vh;
  }
}
.web_notes {
  height: 57vh;
  @media (max-width: 1440px) {
    height: 47vh;
  }
  @media (max-width: 1536px) {
    height: 47vh;
  }
}
.contacts {
  height: 44vh;
  @media (max-width: 1440px) {
    height: 54vh;
  }
  @media (max-width: 1536px) {
    height: 54vh;
  }
}
.leads_notes {
  height: 44vh;
  @media (max-width: 1440px) {
    height: 49vh;
  }
  @media (max-width: 1536px) {
    height: 49vh;
  }
}
.leads_contacts {
  height: 48vh;
  @media (max-width: 1440px) {
    height: 54vh;
  }
  @media (max-width: 1536px) {
    height: 54vh;
  }
}
.select_option {
  color: var(--gray, #6e6e6e);
  font-family: "DM Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.column_data {
  color: var(--black, #161616);
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1; /* 100% */
  text-transform: capitalize;
}
.side_menu {
  color: #666;
  font-family: "DM Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.popover_text {
  color: var(--black, #161616);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border: 0px;
  cursor: pointer;
}
.select_values {
  color: var(--black, #161616);
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.Collapse_icon:hover {
  height: 32px;
  width: 50px;
  background-color: #e8eaed;
  border-radius: 20px 0px 0px 20px;
  border: 0px;
}
.react-tel-input input[type="text"],
.react-tel-input input[type="tel"] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 44px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 1px 2px #e3e3e3 inset;
  line-height: 25px;
  height: 28px;
  width: 103%;
}
.customer_details {
  color: #141414;
  font-family: "DM Sans", Arial, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.customer_tabFont {
  color: var(--Gray, var(--gray, #6e6e6e));
  font-family: "DM Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.customer_subTitle {
  color: var(--black, #131313);
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 550;
  line-height: 120.023%;
  text-transform: capitalize;
}
.contactTable.ant-table-thead > tr > th {
  background: red;
}
._3uApM {
  display: none;
}
.Customer_Satisfaction {
  color: var(--black, #161616);
  font-family: "DM Sans", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  text-transform: capitalize;
}

.loading-div {
  width: 240px;
  height: 240px;
  background-color: #00000073;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.please-wait {
  text-align: center;
  color: #fff;
}